import {
    Component,
    OnDestroy,
    Input,
    OnInit,
    EffectRef,
    effect,
    signal,
    ChangeDetectionStrategy,
    computed
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    MapService,
    LayerService,
    ConfigService,
    LegendService,
    FeatureService
} from 'app/_services/';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { Stroke, Style, Fill, Icon, Circle } from 'ol/style.js';
import { Vector as LayerVector } from 'ol/layer';
import { Vector as SourceVector } from 'ol/source';
import Feature from 'ol/Feature';
import { MatSnackBar } from '@angular/material/snack-bar';
import { transform } from 'ol/proj';
import { getCenter } from 'ol/extent';

@Component({
    selector: 'search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchComponent implements OnDestroy, OnInit {
    @Input() searchEntry: string;
    config: any;
    readonly searchControl = new FormControl('');
    searchFeature: any;
    selectedSearchValue: string;
    column: string;

    readonly hasResults = computed(() => {
        const results = this.filteredResults();

        if (results) {
            return results.length > 0;
        }
        return false;
    });

    readonly coordinate = signal(false);
    readonly showAnimation = signal(false);

    readonly configSubscription: EffectRef;
    readonly filteredResults = signal(undefined);

    constructor(
        readonly snackBar: MatSnackBar,
        private readonly mapService: MapService,
        private readonly layerService: LayerService,
        private readonly configService: ConfigService,
        private readonly legendService: LegendService,
        private readonly http: HttpClient,
        private readonly featureService: FeatureService
    ) {
        this.config = this.configService.config().tools.search;

        this.configSubscription = effect(
            () => {
                const config = this.configService.config();

                if (config) {
                    this.config = config.tools.search;
                }
            },
            { allowSignalWrites: true }
        );

        this.searchControl.valueChanges
            .pipe(debounceTime(750))
            .subscribe(query => {
                if (query.length > 0 && query !== this.selectedSearchValue) {
                    // this.selectedSearchValue = "";
                    this.showAnimation.set(true);
                    // This switch check for matching strings if there is no case it will perform a default search
                    switch (true) {
                        case query.charAt(0) === '_':
                            this.getRelandHex(query);
                            break;
                        // The first case that returns true will be used
                        case /^\d+(?:.\d+)\s?,\s?\d+(?:.\d+)$/.test(query):
                            this.showAnimation.set(false);
                            this.coordinate.set(true);
                    

                            // Only zoom to it when it is clicked
                            // this.zoomToCoordinate(query);
                            break;

                        default:
                            // Replace all commas with points for the search
                            query = query.replace(',', '.');
                            this.search(query).subscribe({
                                next: result => {
                                    this.showAnimation.set(false);
                                    if (
                                        !this.config.type ||
                                        this.config.type == 'custom'
                                    ) {
                                        this.filteredResults.set(result);
                                    } else if (this.config.type === 'pdok') {
                                        this.filteredResults.set(
                                            result.response.docs
                                        );
                                    } else if (this.config.type === 'maps') {
                                        this.filteredResults.set(result);
                                    }
                                },
                                error: (error: HttpErrorResponse) => {
                                    console.error(error);
                                    this.showAnimation.set(false);
                                    this.showSearchError(
                                        error?.error ?? 'Er is iets fout gegaan bij het zoeken.'
                                    );
                                }
                            });
                            break;
                    }
                } else {
                    this.filteredResults.set(undefined);
                }
            });
    }

    ngOnInit(): void {
        // Automatically search for the element if its set
        if (this.searchEntry) {
            
            if (this.searchEntry.charAt(0) === '_') {
                this.getRelandHex(this.searchEntry);
            } else {
                this.search(this.searchEntry).subscribe({
                next: result => {
                    if (result?.length) {
                        // Always get the first item
                        this.goToResult(result[0]);
                    } else {
                        this.snackBar.open('Geen elementen gevonden', '', {
                            duration: 3000,
                            panelClass: 'white-snackbar',
                            verticalPosition: 'top'
                        });
                    }
                },
                error: error => {
                    console.error(error);
                    this.showAnimation.set(false);
                    this.showSearchError(
                        'Er is iets fout gegaan bij het zoeken van het element.'
                    );
                }
            });
            }
        }
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        (<any>window).searchComponent = null;
        // @shouldRemove
        // this.activatedRoute.queryParams.unsubscribe();
    }

    search(query: string): Observable<any> {
        if (!this.config.type || this.config.type == 'custom') {
            // Fallback for old search 14-11-2023
            let types: any = [];
            if (this.config.onlyConfiguredTypes) {
                if (Array.isArray(this.config.types)) {
                    types = this.config.types.map(type => type.type_id);
                } else if (
                    this.config.types &&
                    typeof this.config.types === 'object'
                ) {
                    types = Object.keys(this.config.types).map(key =>
                        parseInt(key)
                    );
                }

                // this.config.types = types;
            }

            query = query.trim();
            query = btoa(query);

            if (environment.public) {
                // Only search for configured types
                if (this.config && this.config.onlyConfiguredTypes) {
                    return this.http.get(
                        environment.api_base_url +
                            '/u_search/' +
                            query +
                            '/organization/' +
                            this.configService.config().organization_id +
                            '/types/' +
                            types.join(','),
                        { responseType: 'json' }
                    );
                }
                return this.http.get(
                    environment.api_base_url +
                        '/u_search/' +
                        query +
                        '/organization/' +
                        this.configService.config().organization_id,
                    { responseType: 'json' }
                );
            }

            if (this.config && this.config.provider) {
                query = this.config.provider + '/' + query;
            } else {
                query = 'mv_search_bag_nederland/' + query;
            }

            // Only search for configured types
            if (this.config && this.config.onlyConfiguredTypes) {
                query += '/types/' + types.join(',');
            }

            return this.http.get(
                `${environment.api_base_url}/search/${query}`,
                {
                    responseType: 'json'
                }
            );
        } else if (this.config.type === 'pdok') {
            return this.http.get(
                `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=${query}`,
                {
                    responseType: 'json'
                }
            );
        } else if (this.config.type === 'maps') {
            query = query.trim();
            query = btoa(query);

            let table;
            this.column;

            // Hier moet de tabel naam uit de kaart worden gehaald, deze is hetzelfde als de laag naam
            this.configService.config().maps.forEach(map => {
                if (this.legendService.isMapVisible(map)) {
                    if (map.search) {
                        this.column = map.search;
                        table = map.source.layers[0].name;
                    }
                }
            });

            if (!table || !this.column) {
                // Todo break off request
            }

            let url;
            if (environment.public) {
                // url = `${environment.api_base_url}/u_search/${table}/${column}/${query}/organization/${this.configService.config().organization_id}`;
                // if (this.config && this.config.onlyConfiguredTypes && types && types.length > 0) {
                //     url += `/types/${types.join(',')}`;
                // }
            } else {
                url = `${environment.api_base_url}/map_search/${table}/${this.column}/${query}`;
            }

            return this.http.get(url, { responseType: 'json' });
        }
    }

    goToResult(result: any): void {
        if (!this.config.type || this.config.type == 'custom') {
            const geometry: any = new GeoJSON().readGeometry(result.geom);

            // Make sure the results layer is enabled
            if (result.layername) {
                this.legendService.enableLayer(result.layername);
            }

            // Check if we have config for this type
            let typeConfig: any;
            if (
                this.config?.types?.length &&
                this.config.types.some(obj => obj?.type_id === result.type_id)
            ) {
                typeConfig = this.config.types.find(
                    obj => obj.type_id === result.type_id
                );
            }

            // Create/update a feature from the results geometry
            const featureLayer = this.layerService.featureLayer();

            if (!typeConfig || typeConfig.select === true) {
                if (!this.searchFeature) {
                    this.searchFeature = new Feature({
                        geometry: geometry
                    });

                    featureLayer.getSource().addFeature(this.searchFeature);
                } else {
                    this.searchFeature.setGeometry(geometry);
                }
            } else if (this.searchFeature) {
                // Remove the searchFeature because it's been replaced by a feature we don't want to see
                featureLayer.getSource().clear();
            }

            this.zoomToPoint(geometry, typeConfig);

            // Load the info by doing a featureInfo click on the location of the result
            this.featureService.deselectFeatures();
            this.featureService.configuration.set(
                this.configService.config().tools.info
            );

            // Wait till zoom animation is done then make a click
            setTimeout(() => {
                this.mapService.map().once('rendercomplete', () => {
                    this.featureService.queryServers(
                        // Detail or grid should be dynamic from the config
                        getCenter(geometry.getExtent()),
                        'detail'
                    );
                });
            }, 1000);

            // Set the search result value
            this.selectedSearchValue = this.getSelectedSearchValue(result);
            this.showAnimation.set(false);
        } else if (this.config.type === 'pdok') {
            this.selectedSearchValue = result;
            let query = result.id;
            this.http
                .get(
                    `https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=${query}&fl=*`,
                    {
                        responseType: 'json'
                    }
                )
                .subscribe((res: any) => {
                    const center = res.response.docs[0].centroide_rd
                        .replace('POINT(', '')
                        .replace(')', '')
                        .split(' ');
                    const mapView = this.mapService.map().getView();
                    const resolution =
                        mapView.getResolution() < 0.8
                            ? mapView.getResolution()
                            : 0.8;

                    mapView.animate({
                        center: center,
                        resolution: resolution,
                        duration: 1000
                    });
                });
        } else if (this.config.type === 'maps') {
            const geometry: any = new GeoJSON().readGeometry(result.geom);

            // Make sure the results layer is enabled
            if (result.layername) {
                this.legendService.enableLayer(result.layername);
            }

            // Check if we have config for this type
            let typeConfig: any;
            if (
                this.config?.types?.length &&
                this.config.types.some(obj => obj?.type_id === result.type_id)
            ) {
                typeConfig = this.config.types.find(
                    obj => obj.type_id === result.type_id
                );
            }

            // Create/update a feature from the results geometry
            const featureLayer = this.layerService.featureLayer();

            if (!typeConfig || typeConfig.select === true) {
                if (!this.searchFeature) {
                    this.searchFeature = new Feature({ geometry });

                    featureLayer.getSource().addFeature(this.searchFeature);
                } else {
                    this.searchFeature.setGeometry(geometry);
                }
            } else if (this.searchFeature) {
                // Remove the searchFeature because it's been replaced by a feature we don't want to see
                featureLayer.getSource().clear();
            }

            this.zoomToPoint(geometry, typeConfig);

            // Load the info by doing a featureInfo click on the location of the result
            this.featureService.deselectFeatures();
            this.featureService.configuration.set(
                this.configService.config().tools.info
            );

            // Wait till zoom animation is done then make a click
            setTimeout(() => {
                this.mapService.map().once('rendercomplete', () => {
                    this.featureService.queryServers(
                        // Detail or grid should be dynamic from the config
                        getCenter(geometry.getExtent()),
                        'detail'
                    );
                });
            }, 1000);

            // Set the search result value
            this.selectedSearchValue = this.getSelectedSearchValue(result);
            this.showAnimation.set(false);
        }
    }

    zoomToPoint(geometry, typeConfig) {
        const mapView = this.mapService.map().getView();

        // For points, center it and zoom to a minimum resolution
        if (geometry.getType() === 'Point') {
            const resolution =
                mapView.getResolution() < 0.8 ? mapView.getResolution() : 0.8;

            // Result is always 28992 change it to config projection
            const coordinate = transform(
                geometry.getCoordinates(),
                'EPSG:28992',
                this.mapService.projection
            );

            if (typeConfig && typeConfig.zoom && typeConfig.searchZoomlevel) {
                mapView.animate({
                    center: coordinate,
                    resolution,
                    duration: 1000,
                    zoom: typeConfig.searchZoomlevel
                });
            } else {
                mapView.animate({
                    center: coordinate,
                    resolution: resolution,
                    duration: 1000
                });
            }
        } else {
            // Zoom to the feature
            if (!typeConfig || typeConfig.zoom === true) {
                if (typeConfig && typeConfig.searchZoomlevel) {
                    this.mapService.map().getView().fit(geometry.getExtent(), {
                        duration: 1000,
                        maxZoom: typeConfig.searchZoomlevel
                    });
                } else {
                    this.mapService.map().getView().fit(geometry.getExtent(), {
                        duration: 1000,
                        maxZoom: 15
                    });
                }
            }
        }
    }

    getSelectedSearchValue(result): string {
        if (!this.config.type || this.config.type == 'custom') {
            if (result) {
                // Set the search result value
                switch (result) {
                    case result.zoekweergave5: {
                        return (
                            result.zoekweergave1 +
                            ' ' +
                            result.zoekweergave2 +
                            ' ' +
                            result.zoekweergave3 +
                            ' ' +
                            result.zoekweergave4 +
                            ' ' +
                            result.zoekweergave5
                        );
                    }
                    case result.zoekweergave4: {
                        return (
                            result.zoekweergave1 +
                            ' ' +
                            result.zoekweergave2 +
                            ' ' +
                            result.zoekweergave3 +
                            ' ' +
                            result.zoekweergave4
                        );
                    }
                    case result.zoekweergave3: {
                        return (
                            result.zoekweergave1 +
                            ' ' +
                            result.zoekweergave2 +
                            ' ' +
                            result.zoekweergave3
                        );
                    }
                    case result.zoekweergave2: {
                        return (
                            result.zoekweergave1 + ' ' + result.zoekweergave2
                        );
                    }
                    default: {
                        return (
                            result.zoekweergave1 + ' ' + result.zoekweergave2
                        );
                    }
                }
            }
        } else if (this.config.type === 'pdok') {
            return result.weergavenaam;
        }
    }

    zoomToCoordinate(): void {
        // convert the coordinate to a usable array
        const coordinate = this.searchControl.value
            .split(',')
            .map(item => parseInt(item, 10));

        // Create a icon
        const iconStyle = new Style({
            fill: new Fill({
                color: 'rgba(0, 51, 255, 0.5)'
            }),
            stroke: new Stroke({
                color: '#0033ff',
                width: 5
            }),
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: `assets/img/icons/map-marker-orange.png`
            })
        });

        const layer = new LayerVector({
            style: iconStyle,
            source: new SourceVector({
                features: [
                    new Feature({
                        geometry: new Point(coordinate),
                        name: 'Coordinaat'
                    })
                ]
            })
        });

        // add the icon on the map
        this.mapService.map().addLayer(layer);

        // Set the map to the coordinate
        this.mapService.map().getView().animate({ center: coordinate });

        // Clear the coordinate
        this.coordinate.set(false);
    }

    clearSearch(): void {
        this.searchControl.setValue('');
        this.filteredResults.set(undefined);
    }

    private showSearchError(message: string): void {
        this.snackBar.open(message, 'Ok', {
            duration: 5000,
            panelClass: 'white-snackbar',
            verticalPosition: 'bottom'
        });
    }

    getRelandHex(query) {
        this.http.get(`https://eminet.nl/api/index.php?type=get_reservering&key=Z5ZD3E40cbAgYQh&res_id=${query.slice(1)}`, { responseType: 'json'})
        .subscribe((result: any) => {          
            this.http.post(environment.api_base_url + '/reland/offer', result, { responseType: 'json' })
            .subscribe((res: any) => {
                console.log(res);
                const features = new GeoJSON().readFeatures(JSON.parse(res), {
                    // dataProjection: 'EPSG:4326',
                    featureProjection: this.mapService.projection
                })
                const layerSource = new SourceVector();
                layerSource.addFeatures(features);
                const layer = new LayerVector({
                    source: layerSource,
                    style: new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: 'rgba(255,0,0,0.4)'
                            }),
                            stroke: new Stroke({
                                color: '#cc3333',
                                width: 1.25
                            }),
                            radius: 5
                        }),
                        fill: new Fill({
                            color: 'rgba(255,0,0,0.4)'
                        }),
                        stroke: new Stroke({
                            color: '#cc3333',
                            width: 1.25
                        }),
                    })
                });
                // const layer = new WebGLPoints({
                //     source: layerSource,
                //     style: {
                //         symbol: {
                //             symbolType: <SymbolType>'circle',
                //             size: ['interpolate', ['exponential', 2.5], ['zoom'], 2, 1, 14, 32],
                //             color: 'rgb(255, 0, 0)',
                //             offset: [0, 0],
                //             opacity: 0.95,
                //         },
                //     }
                // })

                this.mapService.map().addLayer(layer);

                this.showAnimation.set(false);
            })
        });
    }
}
