import { Observable } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VrViewerDialogComponent } from 'app/tools/vr-photo/vr-viewer/vr-viewer';
import { MapService } from './map.service';
import { LayerService } from './layer.service';
import { DetailService } from './detail.service';
import { GridService } from './grid.service';
import { ConfigService } from './config.service';
import { EditService } from './edit.service';
import { SidenavService } from './sidenav.service';
import { fromCircle } from 'ol/geom/Polygon';
import { Circle } from 'ol/geom';
import { intersects, and } from 'ol/format/filter';
import WFS from 'ol/format/WFS';
import { parseString, Builder } from 'xml2js';
import { MediaDialogComponent } from 'app/grid/dialogs/media.dialog';
import { Vector } from 'ol/layer';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for all the functions for features
 */
export class FeatureService {
    clicks = [];

    // The geometry property of the server, configured for mapserver by default
    readonly configuration = signal(undefined);
    drawFeatures = false;

    private readonly ROW_LIMIT = 500;

    constructor(
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly layerService: LayerService,
        private readonly sidenavService: SidenavService,
        private readonly mapService: MapService,
        private readonly detailService: DetailService,
        private readonly gridService: GridService,
        private readonly configService: ConfigService,
        private readonly editService: EditService
    ) {}

    private highlightFeatures(features: any): void {
        const layer = this.drawFeatures
            ? this.layerService.drawingLayer()
            : this.layerService.featureLayer();

        layer.getSource().addFeatures(features);
    }

    readFeatures(features: any, layer): void {
        // this.deselectFeatures();

        this.highlightFeatures(features);

        let collection = {
            layers: [{
                title: 'klik',
                features
            }],
            source: layer,
            id: layer.get('id')
        };
        if (!this.clicks) this.clicks = [];

        this.clicks.push(collection);

        this.action('grid');
    }

    private getWFSFeatures(
        featureTypes: string[],
        serverConfig: any,
        hitBox: any,
        layer = undefined
    ): Observable<any> {
        const filters = this.queryGeoserver(featureTypes);

        let expandedFilters;
        for (const filterName of Object.keys(filters)) {
            const filterValue = filters[filterName];

            // Make sure expandedFilters is always an empty array
            expandedFilters = [];

            // Convert CQL filter to a WFS filter
            const filter = this.filterConversion(filterValue);

            filter.layers = [filterName];

            // Only push if it doesn't already exist, remove NOTNULL filter since this isn't supported
            if (
                !expandedFilters.find(
                    f =>
                        f.layers === filter.layers &&
                        f.type === filter.type &&
                        f.property === filter.property &&
                        f.value === filter.value
                ) &&
                filter.value != 'NOTNULL'
            ) {
                expandedFilters.push(filter);
            }
        }

        const request = this.buildWfsRequest(
            featureTypes,
            serverConfig,
            hitBox,
            expandedFilters
        );
        let headers = new HttpHeaders();

        // Authenticate for geoserver
        if (layer && layer.get('authorization')) {
            headers = headers.append(
                'Authorization',
                layer.get('authorization')
            );
        }

        // Get features through Ajax request
        return this.http.post(serverConfig.resource, request, {
            headers: headers,
            responseType: 'text'
        });
    }

    private filterConversion(filter): any {
        const [property, operator, ...values] = filter.split(' ');

        let type = '';
        switch (operator) {
            case '<':
                type = 'lessThan';
                break;
            case '>':
                type = 'greaterThan';
                break;
            case '<=':
                type = 'lessThanOrEqualTo';
                break;
            case '>=':
                type = 'greaterThanOrEqualTo';
                break;
            case '==':
                type = 'equalTo';
                break;
            default:
                type = '';
        }

        const value = values.join(' ');

        return {
            property,
            type,
            value
        };
    }

    private readWFSFeatures(response: string, server: any): any {
        const parser = new WFS();
        const features = parser.readFeatures(response);
        const highlightFeatures = [];
        let sortedFeatures = [];
        let isLimited = false;

        // Split the features into seperate featuretypes
        for (const f of features) {
            const featureId = String(f.getId());
            let featureType = featureId.includes('.')
                ? featureId.split('.')[0]
                : featureId.split('_')[0];

            let existingFeature = sortedFeatures.find(
                obj => obj.title === featureType
            );

            if (!existingFeature) {
                existingFeature = { title: featureType, features: [] };

                sortedFeatures = [...sortedFeatures, existingFeature];
            }

            if (
                !isLimited &&
                (!existingFeature.features ||
                    existingFeature.features.length <= this.ROW_LIMIT)
            ) {
                existingFeature.features.push(f);

                highlightFeatures.push(f);

                if (existingFeature.features.length > this.ROW_LIMIT) {
                    isLimited = true;
                }
            }
        }

        if (!server.disableHighlight) {
            this.highlightFeatures(highlightFeatures);
        }

        if (isLimited) {
            this.snackBar.open(
                `Limiet tot ${this.ROW_LIMIT} is ingeschakeld`,
                'Sluiten',
                {
                    panelClass: 'white-snackbar',
                    verticalPosition: 'top',
                    duration: 10000
                }
            );
        }

        return sortedFeatures;
    }

    /**
     * Send a query to all servers to check for features at the given coordinate
     * @param  geometry               The geometry we want to have all features for
     * @param  action="featureinfo"   The action to execute after querying
     */
    queryServers(geometry: any, action = 'featureinfo'): void {
        const layers = this.mapService.map().getLayers().getArray();
        const viewResolution = this.mapService.map().getView().getResolution();

        layers.forEach((lyr: any) => {
            if (this.isLayerVisible(lyr, viewResolution)) {
                if (!lyr.get('click')) return;
                if (lyr.getSource() &&  lyr instanceof Vector) {
                    const info = this.getServerInfo(lyr);

                    let hitPolygon = this.getHitPolygon(
                        geometry,
                        info.radius
                    );

                    const fs = lyr.getSource().getFeaturesInExtent(hitPolygon.getExtent());
                    if (!fs.length) return; 

                   const layers = [{
                        features: fs,
                        title: lyr.get('title')
                    }]

                    // If there are no features skip the rest of the code for optimisation
                    if (layers.length) {
                        let collection = {
                            layers,
                            source: lyr,
                            id: lyr.get('id')
                        };

                        this.clicks.push(collection);

                        this.action('grid');
                    }
                } else {
                    const filteredClickLayers =
                        this.getFilteredClickLayers(lyr);
                    const info = this.getServerInfo(lyr);

                    let hitPolygon = this.getHitPolygon(
                        geometry,
                        info.radius
                    );

                    this.getWFSFeatures(
                        filteredClickLayers,
                        info,
                        hitPolygon,
                        lyr
                    ).subscribe((result: any) => {
                        const layers = this.readWFSFeatures(result, info);

                        // If there are no features skip the rest of the code for optimisation
                        if (layers.length) {
                            let collection = {
                                layers,
                                source: lyr,
                                id: lyr.get('id')
                            };
    
                            this.clicks.push(collection);
    
                            this.action(lyr.get('click'));
                        }
                    });
                }
            }
        });

        if (this.configuration()) {
            this.configuration().forEach((server: any, i) => {
                let featureTypes: string[] = [];

                let layers: string[];
                if (action === 'vrphoto') {
                    layers = server.gridLayers;
                } else if (server.gridLayers && server.gridLayers.length) {
                    action = 'grid';
                    layers = server.gridLayers;
                } else if (server.layers && server.layers.length) {
                    action = 'detail';
                    layers = server.layers;
                }

                // Add the proper layers to the protocol
                if (layers && !server.ignoreActiveLayers) {
                    // Only add active layers
                    const maps = this.mapService.map().getLayers().getArray();
                    maps.forEach((lyr: any) => {
                        // Check if this layer can be active within this map (lyr)
                        if (
                            lyr.getVisible() &&
                            typeof lyr.getSource().getParams === 'function' &&
                            lyr.getSource().getParams() &&
                            lyr.getSource().getParams().LAYERS
                        ) {
                            const res = this.mapService
                                .map()
                                .getView()
                                .getResolution();

                            if (
                                lyr.getMinResolution() < res &&
                                res < lyr.getMaxResolution()
                            ) {
                                for (const l of layers) {
                                    if (
                                        lyr
                                            .getSource()
                                            .getParams()
                                            .LAYERS.indexOf(l) > -1
                                    ) {
                                        featureTypes.push(l);
                                    }
                                }
                            }
                        }
                    });
                } else if (layers) {
                    featureTypes = layers;
                }

                let hitPolygon = this.getHitPolygon(
                    geometry,
                    server.hitTolerance
                );

                if (featureTypes.length) {
                    this.getWFSFeatures(
                        featureTypes,
                        server,
                        hitPolygon
                    ).subscribe((result: any) => {
                        const layers = this.readWFSFeatures(result, server);
                        if (layers.length) {
                            let collection = {
                                layers,
                                source: server,
                                id: i
                            };
    
                            this.clicks.push(collection);
    
                            this.action(action);
                        }
                    });
                } else {
                    this.sidenavService.tabIndex.set(0);
                }
            });
        }
    }

    private isLayerVisible(lyr: any, viewResolution: number): boolean {
        if (
            lyr.getVisible() &&
            lyr.getSource() &&
            lyr.getSource().getParams &&
            lyr.getSource().getParams().LAYERS ||
            lyr.getSource() instanceof Vector &&
            lyr.getVisible()
        ) {
            const layerMinResolution = lyr.getMinResolution();
            const layerMaxResolution = lyr.getMaxResolution();

            return (
                layerMinResolution < viewResolution &&
                viewResolution < layerMaxResolution
            );
        }
        console.log(lyr)

        return false;
    }

    private getFilteredClickLayers(lyr: any): string[] {
        const names = lyr.getSource().getParams().LAYERS;
        const types = lyr.get('clickLayers');
        const filteredClickLayers: string[] = [];

        names.forEach((name: string) => {
            if (types.includes(name)) {
                if (name.includes(',')) {
                    const namesArr = name.split(',');
                    filteredClickLayers.push(...namesArr);
                } else {
                    filteredClickLayers.push(name);
                }
            }
        });

        return filteredClickLayers;
    }

    private getServerInfo(lyr: any): any {
        const server: any = {
            resource: lyr.getSource().getUrl(),
            geom: lyr.get('geometry') ? lyr.get('geometry') : 'geom',
            radius: lyr.get('radius') ? lyr.get('radius') : undefined
        };

        return server;
    }

    private getHitPolygon(geometry: any, hitTolerance?: number): any {
        let hitPolygon: any = geometry;

        if (Array.isArray(geometry)) {
            hitPolygon = fromCircle(new Circle(geometry, hitTolerance || 1));
        } else if (geometry.getType() === 'Circle') {
            hitPolygon = fromCircle(geometry);
        }

        return hitPolygon;
    }

    private action(action): void {
        switch (action) {
            case 'side':
                this.detailService.setFeatures(this.clicks);
                break;
            case 'detail':
                this.detailService.setFeatures(this.clicks);
                break;
            case 'grid':
                this.gridService.features.set(this.clicks);
                this.gridService.featuresChange.next(
                    this.gridService.features()
                );

                break;
            case 'edit':
                this.editService.feature.set(undefined);
                // Now edit the first feature
                if (this.clicks[0].layers[0]) {
                    this.editService.setfeatureType(
                        Object.keys(this.clicks)[0]
                    );
                    this.editService.feature.set(this.clicks[0].layers[0]);
                } else {
                    this.snackBar.open('Geen element gevonden', '', {
                        duration: 3000,
                        panelClass: 'white-snackbar',
                        verticalPosition: 'top'
                    });
                }
                break;
            case 'vrphoto':
                this.dialog.open(VrViewerDialogComponent, {
                    height: '80vh',
                    width: '75vw',
                    data: {
                        imageUrl:
                            this.clicks[0].layers[0].features[0].get('url')
                    }
                });
                break;
            case 'image':
                this.dialog.open(MediaDialogComponent, {
                    data: {
                        title: 'Afbeelding',
                        imageSrc:
                            this.clicks[0].layers[0].features[0].get('url')
                    }
                });
                break;
            case 'video':
                this.dialog.open(MediaDialogComponent, {
                    data: {
                        title: 'Video',
                        videoSrc:
                            this.clicks[0].layers[0].features[0].get('url')
                    }
                });
                break;
            case 'url':
                window.open(this.clicks[0].layers[0].features[0].get('url'));
                break;

            default:
                break;
        }

        if (!this.gridService.features() && !this.detailService.features) {
            this.snackBar.open('Geen elementen gevonden', '', {
                duration: 3000,
                panelClass: 'white-snackbar',
                verticalPosition: 'top'
            });
        }
    }

    private queryGeoserver(protocol): any {
        // Get CQL filters from the config and add them to the filters array so we can add them to the WFS request later
        const filters = {};
        const disabledFilters = [];

        protocol
            .filter(s => s.type === 'geoserver')
            .forEach(geoServerConfig => {
                this.configService.config().maps.forEach(mapName => {
                    const map = this.configService.config().maps[mapName];
                    geoServerConfig.forEach(l => {
                        if (
                            map.featureTypes === l &&
                            !disabledFilters.includes(l)
                        ) {
                            // Because of the featureTypes parameter we are sure we have a GeoServer layer here
                            // now we want to check if this layer is active, if not we continue to the next iteration

                            let layerVisible = false;
                            const mapLayers = this.mapService
                                .map()
                                .getLayers()
                                .getArray();

                            for (const lyr of mapLayers) {
                                if (lyr.get('title') === map.name) {
                                    // Okay, this is the layer corresponding to our map, let's check if it's active
                                    if (lyr.getVisible()) {
                                        layerVisible = true;
                                    }
                                }
                            }

                            if (
                                layerVisible &&
                                map.source.params.find(
                                    p => p.name === 'cql_filter'
                                )
                            ) {
                                // Get the CQL filter from the object and replace current_date with the actual current date
                                // @shouldRemove
                                const moment = require('moment');
                                if (!filters[l]) {
                                    filters[l] = map.source.params
                                        .find(p => p.name === 'cql_filter')
                                        .value.replace(
                                            'current_date',
                                            moment()
                                                .format('YYYY-MM-DD')
                                                .toString()
                                        );
                                } else {
                                    // There is already a filter for this layer, we want to prevent double filters because this
                                    // can give issues when they are in conflict (date < today && date > today)
                                    if (
                                        filters[l] !=
                                        map.source.params
                                            .find(p => p.name === 'cql_filter')
                                            .value.replace(
                                                'current_date',
                                                moment()
                                                    .format('YYYY-MM-DD')
                                                    .toString()
                                            )
                                    ) {
                                        console.log(
                                            'We have a different kind of monster here'
                                        );
                                        // This is a different filter, disable it by removing the filter
                                        delete filters[l];
                                        disabledFilters.push(l);
                                    }
                                }
                            }
                        }
                    });
                });
            });

        return filters;
    }

    private buildWfsRequest(
        featureTypes: string[],
        serverConfig,
        hitBox,
        filters
    ): any {
        const projection = this.mapService
            .map()
            .getView()
            .getProjection()
            .getCode();

        const requestJson: any = {
            featureNS: '',
            featureTypes,
            srsName: projection,
            maxFeatures: 4000,
            filter: intersects(serverConfig.geom, hitBox, projection)
        };

        if (
            serverConfig.type === 'mapserver' &&
            !serverConfig.name &&
            serverConfig.name !== 'gisarts_mapserver'
        ) {
            requestJson.featurePrefix = 'ms';
        }

        // Add any additional filters
        if (filters && filters.length > 0) {
            const conditions = [
                intersects(serverConfig.geom, hitBox, projection)
            ];

            for (const filter of filters) {
                conditions.push(
                    intersects[filter.type](filter.property, filter.value)
                );
            }

            requestJson.filter = and.apply(this, conditions);
        }

        const featureRequest = new WFS().writeGetFeature(requestJson);

        const serializedXML = new XMLSerializer().serializeToString(
            featureRequest
        );

        let xml;

        // Apply filters only to selected featureTypes
        parseString(serializedXML, (err: any, res: any) => {
            // Loop over the queries
            if (res && res.getFeature) {
                for (let i = 0; i < res.GetFeature.Query.length; i++) {
                    const query = res.GetFeature.Query[i];
                    const featureType = query.$.typeName.replace('ms:', '');

                    // Remove the filters that shouldn't have been set from the And clause
                    if (query.Filter[0].And) {
                        for (const key of Object.keys(query.Filter[0].And[0])) {
                            query.Filter[0].And[0][key];
                            // Get the associated filter from the config
                            let configFilter;

                            if (key === 'Intersects') {
                                // First filter is the geom filter, we always want this so continue to the next iteration
                                continue;
                            } else if (
                                key === 'PropertyIsGreaterThanOrEqualTo'
                            ) {
                                configFilter = filters.find(
                                    f => f.type === 'greaterThanOrEqualTo'
                                );
                            }

                            if (
                                configFilter &&
                                configFilter.layers.indexOf(featureType) === -1
                            ) {
                                // Remove filter, this featureType shouldn't have it
                                delete query.Filter[0].And[0][key];

                                // Remove And from the filter when there is only one item in the array
                                if (query.Filter[0].And.length === 1) {
                                    const intersects = {
                                        ...query.Filter[0].And[0].Intersects
                                    };
                                    delete query.Filter[0].And;
                                    query.Filter[0].Intersects = intersects[0];
                                }
                            }
                        }
                    }
                }
            }

            const builder = new Builder();
            xml = builder.buildObject(res);
        });

        return xml;
    }

    /**
     * Clear all features
     */
    deselectFeatures(): void {
        // Clear layer
        this.layerService.featureLayer().getSource().clear();
        this.layerService.layers.featureLayer = null;

        // Remove features
        this.editService.feature.set(undefined);
        this.gridService.features.set(undefined);

        this.gridService.featuresChange.next(this.gridService.features());

        this.clicks = [];
    }

    // @shouldRemove
    // readWMSFeatures(features: any, layer) {
    //     let featureTypeArray: any = undefined;
    //     featureTypeArray = {};
    //     if(this.interactionService.info.active){
    //         if(features[0] != null || features[0] != undefined) {
    //             this.snackBar.open(features, "Sluiten", {
    //                 panelClass: 'white-snackbar',
    //                 verticalPosition: "top",
    //                 duration: 10000
    //             });
    //         } else {
    //             this.snackBar.dismiss();
    //         }

    //         if (Object.keys(features).length > 1) {
    //             this.readFeatures(new GeoJSON().readFeatures(features), layer)
    //             this.gridService.features = this.clicks;
    //         }
    //     }
    // }

    // @shouldRemove
    // getWMSFeatures(geometry, layer) {
    //     const res = this.mapService.map().getView().getResolution();
    //     // And check if the map is visible
    //     if (layer.getMinResolution() < res && res < layer.getMaxResolution() && layer.getVisible()) {

    //         const url = layer.getSource().getFeatureInfoUrl(
    //             geometry,
    //             this.mapService.resolution,
    //             'EPSG:28992',
    //             {INFO_FORMAT: 'application/json'}
    //         );

    //         let headers = new HttpHeaders();

    //         // Authenticate for geoserver
    //         let data;
    //         if (layer.get('authorization')) {
    //             headers = headers.append('Authorization', layer.get('authorization'));

    //             data = {headers, withCredentials: true};
    //         }

    //         this.http.get(url, data ? data : {}).toPromise()
    //             .then(res => this.readWMSFeatures(res, layer))
    //             .catch(res => {
    //                 const url = layer.getSource().getFeatureInfoUrl(
    //                     geometry,
    //                     this.mapService.resolution,
    //                     'EPSG:28992',
    //                     {INFO_FORMAT: 'application/geojson'}
    //                 );
    //                 this.http.get(url, data ? data : {}).toPromise()
    //                     .then(res => this.readWMSFeatures(res, layer))
    //             });
    //     }
    // }
}
