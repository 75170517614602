import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ConfigService } from 'app/_services';
import { environment } from 'environments/environment';

@Component({
    selector: 'cook-two-factor',
    templateUrl: 'two-factor.component.html',
    styleUrls: ['two-factor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * This component handles the 2FA
 */
export class TwoFactorComponent {
    totpcode = '';

    readonly error = signal('');
    readonly environment = environment;

    constructor(
        readonly configService: ConfigService,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly http: HttpClient
    ) {}

    sendCode(): void {
        const url = environment.base_url + '/two-factor-challenge';

        this.http
            .post(
                url,
                { code: this.totpcode },
                {
                    responseType: 'json',
                    observe: 'response'
                }
            )
            .subscribe(
                {
                    next: (res: any) => {
                        console.log(res);
                        this.authService.setDetails(res.body);
                        if (res.body.type === 'reset') {
                            // Indicates a succesful login that requires a password reset, send user to the password reset screen
                            this.router.navigate(['wachtwoord/verlopen']);
                        } else {
                            this.authService.loadApp();
                        }
                    },
                    error: (error) => {
                        if (error.status === 401) {
                            this.error.set('Incorrecte code.');
                        } else if (error.status === 429) {
                            this.error.set(
                                'Te veel pogingen, probeer het later opnieuw.'
                            );
                        } else if (error.status === 422) {
                            this.error.set('De ingevoerde code is ongeldig.');
                        } else {
                            this.error.set('Er is een fout opgetreden.');
                        }
                    }
                }
            );
    }
}
