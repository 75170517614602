export const environment = {
    production: true,
    development: false,
    public: false,
    base_url: `//api.${
        window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]
    }`,
    api_base_url: `//api.${
        window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]
    }/api`,
    subDirectory: '/cook/',
    loginPrefix: 'rld_',
    cubejs_url: 'https://cubejs.gisarts.nl',
    active_directory: false
};
